
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { mapGetters, mapState } from "vuex";
import { FeedbackType } from "@/schemas/Enums";

@Component({
  name: "GroupResults",
  components: { Score },
  computed: {
    ...mapGetters("session", ["getIndividualScores", "getGroupScore"]),
    ...mapState("session", ["session"]),
  },
})
export default class GroupResults extends Vue {
  get displayGroupScore(): boolean {
    if (this.session.setting) {
      let settings = this.session.setting.feedback_details.find(
        (setting: {
          type: FeedbackType;
          display_feedback_message: boolean;
          display_score?: boolean;
          display_indiv_scores?: boolean;
          display_group_score?: boolean;
        }) => setting.type === FeedbackType.Performance
      );
      return settings ? settings.display_group_score : false;
    }
    return false;
  }

  get displayIndividualScore(): boolean {
    if (this.session.setting) {
      let settings = this.session.setting.feedback_details.find(
        (setting: {
          type: FeedbackType;
          display_feedback_message: boolean;
          display_score?: boolean;
          display_indiv_scores?: boolean;
          display_group_score?: boolean;
        }) => setting.type === FeedbackType.Performance
      );
      return settings ? settings.display_indiv_scores : false;
    }
    return false;
  }

  created(): void{
    let somme=0
    let array = JSON.parse(localStorage.getItem("ranked_items"))
    let nbItem=0
    if(array==null){
      nbItem=JSON.parse(localStorage.getItem("itemNumber"))
    }
    else{
      nbItem=array.length
    }
    for(let i=1; i<= nbItem ; i++){
      somme = somme + Math.abs(i - (nbItem -i + 1));
    }

    this.maxScore = somme;


  }

  data(): any {
    return {
      maxScore: 112,
    }
  }
}
